<template>
  <Header />
  <main>
    <section class="hero">
      <SoloSlider :soloSlider="soloSlider" />
      <div class="container">
        <div class="hero__wrapper">
          <div class="hero__text" v-if="Object.keys(mainText).length > 0">
            <h1 class="hero__title">{{ mainText.name.split(" ")[0] }}</h1>
            <div class="hero__desc">
              <span v-if="mainText.name.split(' ')[1]">
                {{ mainText.name.split(" ")[1] }}
                {{ mainText.name.split(" ")[2] }}
              </span>
              <p v-if="mainText.name_disc">{{ mainText.name_disc }}</p>
            </div>
          </div>
          <div class="hero__text_adapt" v-if="Object.keys(mainText).length > 0">
            <h1 class="hero__title">
              {{ mainText.name.split(" ")[0] }} <br />
              {{ mainText.name.split(" ")[1] }}
              {{ mainText.name.split(" ")[2] }}
            </h1>
            <div class="hero__desc" v-if="mainText.name_disc">
              {{ mainText.name_disc }}
            </div>
          </div>
          <div class="hero__btn">
            <button @click="ProgrammSelect()">Выбрать программу</button>
          </div>
        </div>
      </div>
    </section>
    <section class="upcoming" ref="programms">
      <div class="container">
        <h2 class="title upcoming__title">Ближайшие программы</h2>
        <div class="upcoming__grid">
          <div
            v-for="list in programms_arr"
            :key="list.slug"
            class="upcoming__card upcoming-card"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div class="upcoming-card__row" @click="GoToProgramm(list.slug)">
              <div class="upcoming-card__column">
                <div class="upcoming-card__prefs">
                  <ul class="upcoming-card__list">
                    <li
                      class="upcoming-card__pref"
                      v-for="item in list.tags_prog.slice(0, 2)"
                      :key="item.name"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                  <ul class="upcoming-card__list">
                    <li
                      class="upcoming-card__pref"
                      v-for="item in list.tags_prog.slice(2, 5)"
                      :key="item.name"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
                <div class="upcoming-card__price">{{ list.price_main }}₽</div>
              </div>
              <div class="upcoming-card__image">
                <picture
                  ><source :srcset="getPrevieImage(list.get_preview)" />
                  <img :src="getPrevieImage(list.get_preview)" alt=""
                /></picture>
              </div>
              <div class="upcoming-card__image_adapt">
                <picture
                  ><source :srcset="getPrevieImage(list.get_preview)" />
                  <img :src="getPrevieImage(list.get_preview)" alt=""
                /></picture>
              </div>
            </div>
            <div class="upcoming-card__main">
              <div
                class="upcoming-card__title"
                @click="GoToProgramm(list.slug)"
              >
                {{ list.title }}
              </div>
              <div class="upcoming-card__text" @click="GoToProgramm(list.slug)">
                <div class="upcoming-card__desc">{{ list.small_disc }}</div>
                <div class="upcoming-card__age">
                  <span>{{ list.age_main }}</span>
                </div>
              </div>
              <div class="upcoming-card__buttons">
                <div class="upcoming-card__btn_vio">
                  <button @click="GoToReserve(list.id)">Забронировать</button>
                </div>
                <div class="upcoming-card__btn_whi">
                  <button @click="GoToProgramm(list.slug)">Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--                  <div class="upcoming__btn show"><span class="more">Показать больше программ</span> <span
                       class="less">Скрыть</span>
                 </div> -->
      </div>
    </section>
    <section class="about" :class="{ new_bottom: card_upper }">
      <div class="container ovh-hidden">
        <div class="about__title title">О лагере</div>
        <div class="about__column">
          <div class="about__card about-card relative-pos" ref="card2">
            <div class="card-pos_circle">
              <img src="@/assets/img/about/circle.svg" />
            </div>
            <div class="about-card__image">
              <img src="@/assets/img/main/1lastblock.jpg" alt="" />
            </div>
            <div class="about-card__title">Лидерлайф - это</div>
            <div class="about-card__text">
              <p>
                Команда профессионалов, которая на протяжении 15 лет создает
                программные лагеря. Мы заботимся о физическом и эмоциональном
                комфорте каждого ребенка и искренне горим тем, что делаем.
              </p>
              <p>
                Наша команда на 90% состоит из людей, которые росли вместе с
                нами – повзрослевших участников лагеря. Для нас это
                доказательство того, что мы вдохновляем детей и находимся на
                верном пути.
              </p>
            </div>
          </div>
          <div
            class="about__card about-card relative-pos"
            ref="card3"
            :class="card2done ? 'animationCard2' : 'default'"
          >
            <div class="card-pos_circle_left">
              <img src="@/assets/img/about/circle.svg" />
            </div>
            <div class="about-card__image">
              <img src="@/assets/img/main/2lastblock.jpg" alt="" />
            </div>
            <div class="about-card__title">Развитие и командообразование</div>
            <div class="about-card__text">
              <p>
                Мы обожаем открывать новое и делиться этими открытиями и опытом
                с ребятами. Каждый мастер-класс и мероприятие в лагере
                направлены на то, чтобы участники узнали что-то для себя и про
                себя или прокачали какой-то скил.
              </p>
            </div>
          </div>
          <div
            class="about__card about-card relative-pos"
            :class="card3done ? 'animationCard3' : 'default'"
          >
            <div class="card-pos_circle">
              <img src="@/assets/img/about/circle.svg" />
            </div>
            <div class="about-card__image">
              <img src="@/assets/img/main/3lastblock.jpg" alt="" />
            </div>
            <div class="about-card__title">
              Теплая атмосфера и индивидуальный подход
            </div>
            <div class="about-card__text">
              <p>
                Мы хотим, чтобы каждый участник чувствовал максимальный комфорт
                на смене и знал, что его всегда поддержат. Для нас важно найти
                подход к каждому ребенку и стать для него не только наставником,
                но и другом, к которому всегда можно обратиться.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="team">
      <div class="team__row">
        <div class="team__title title">Наша команда</div>
        <div class="team__link">
          <router-link to="/team">Вся команда</router-link>
        </div>
      </div>
      <TeamCarousel :teams="teams" />
    </section>
    <section class="gallery" ref="galery">
      <div class="gallery__title title">Галерея</div>
      <MainCarousel
        :galery="galery"
        :mouseWheel="mouseWheel"
        :cssMode="cssMode"
        v-on:active="currentSlider = $event"
      />
    </section>
    <section class="faq">
      <div class="container">
        <div class="faq__title title">Вопрос-ответ</div>
        <div id="faq-question" class="faq-question">
          <div
            class="faq-item"
            v-for="list in info"
            :key="list.name"
            @click="list.active = !list.active"
          >
            <div class="faq-item__title" :class="{ active: list.active }">
              <span>{{ list.name }}</span>
            </div>
            <div
              class="faq-item__subtitle"
              :class="{ styleObject: list.active }"
            >
              <span v-html="list.disc"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style type="text/css">
/*.transform_active {
  position: absolute;
  animation: firstblock 5s;
  animation-fill-mode: forwards;
}

.about__column {
   position: relative;
}

.transform_active_last {
  position: absolute;
  animation: lastblock 5s;
  animation-fill-mode: forwards;
}

.new_bottom {
   padding-bottom: 330px;
}

@keyframes firstblock {
  0% { top: 120px; bottom: translateY(0%); }
  100% { top: 80px; transform: translateY(0%); }
}

@keyframes lastblock {
  0% { top: 300px; transform: translateY(0%); }
  100% { top: 230px; transform: translateY(0%); }
}*/

.ovh-hidden {
  overflow: hidden;
}

.about-card {
  transition: 1s;
}

.relative-pos {
  position: relative;
}

.card-pos_circle_left {
  position: absolute;
  left: 0px;
  top: 50px;
  transform: translate(-65px, -100px);
}

.card-pos_circle {
  position: absolute;
  right: 0px;
  top: 50px;
  transform: translate(50px, -100px);
}

.animationCard2 {
  z-index: 3;
}

.animationCard3 {
  z-index: 5;
}

@media (min-width: 1025px) {
  .default {
    margin-top: 0px !important;
    opacity: 0 !important;
  }

  .animationCard3 {
    margin-top: -350px !important;
    opacity: 1 !important;
  }

  .animationCard2 {
    margin-top: -450px !important;
    opacity: 1 !important;
  }
}

.none_scroll {
  overflow-y: hidden !important;
}
</style>

<script>
import axios from "axios";
import Header from "@/components/Header/Header";
import TeamCarousel from "@/components/Carousel/TeamCarousel";
import MainCarousel from "@/components/Carousel/MainCarousel";
import SoloSlider from "@/components/Carousel/SoloSlider";
import { onMounted } from "@vue/runtime-core";
import AOS from "aos";
import { WOW } from "wowjs";
// import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default {
  name: "HomeView",
  data() {
    return {
      teams: [],
      info: [
        {
          name: "Что входит в стоимость?",
          disc: "<b>В стоимость программы входит все!</b> Питание, проживание, трансфер,   мед страховка и медобслуживание, все виды активностей, бассейн, лазертаг, и т.д. Ни за что доплачивать не нужно!",
          active: false,
        },
        {
          name: "Можно ли оформить все документы дистанционно?",
          disc: "Да, всё можно сделать онлайн: забронировать путевку, оформить договор и произвести оплату.",
          active: false,
        },
        {
          name: "Разрешены ли мобильные телефоны в лагере?",
          disc: "Живое общение – очень важно для нас, но мы ценим личные границы, поэтому не забираем телефоны. Хотя даже в свободное время стараемся вовлекать в совместный досуг и общение, чтобы ребята не сидели в телефонах не потому, что им нельзя, а потому, что им не хочется.",
          active: false,
        },
        {
          name: "Как узнать, как дела у моего ребенка?",
          disc: 'Каждый день мы публикуем новости и фотографии со смены в нашем <a href="https://t.me/+rYn9DavI6s80OTgy" _blank="true">телеграм-канале</a>. А если Вы захотите узнать лично, Вы сможете связаться с руководителем, телефон которого мы присылаем в день выезда',
          active: false,
        },
        {
          name: "Предоставляете ли вы документы для компенсации на работе?",
          disc: 'Да, мы предоставим все необходимые документы, для этого необходимо позвонить в офис или написать на почту <a href="mailto:camp@liderlife.ru" _blank="true">camp@liderlife.ru</a>',
          active: false,
        },
        {
          name: "У меня еще остались вопросы",
          disc: 'Мы будем рады ответить на них – <a href="tel:8 (495) 649-63-60" _blank="true"> 8 (495) 649-63-60 </a>.',
          active: false,
        },
      ],
      windowTop: "",
      block_scroll: "",
      programms_arr: [],
      mainText: [],

      card2: null,
      card3: null,

      done: "",
      card2done: false,
      card3done: false,

      card_upper: false,

      soloSlider: [],
      galery: [],

      currentSlider: 0,
      allSliders: 0,

      scrollTogle: true,

      open: true,

      mouseWheel: false,
      cssMode: false,
    };
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";

      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("sub_id");
      localStorage.removeItem("user");

      this.$store.commit("removeUser");
      this.$store.commit("removeUserInfo");
      this.$store.commit("removeToken");
      this.$store.commit("removeSub");
    },
    getPrevieImage(image) {
      return `${process.env.VUE_APP_API_URL || ""}${image}`;
    },
    showBlock(index) {
      this.showJobBlock = index;
    },
    GoToProgramm(redirect) {
      this.$router.push(`/programms/${redirect}`);
    },
    GoToReserve(programmId) {
      this.$router.push({ path: "/reserve", query: { progId: programmId } });
    },
    ProgrammSelect() {
      this.$refs.programms.scrollIntoView({ behavior: "smooth" });
    },
    async GetTeams() {
      await axios
        .get(`/api/v1/team/`)
        .then((response) => {
          this.teams = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async GetProgramms() {
      /* FYI: Захардкоженно до лета, там дальше посмотрим */
      const getOrderBySlug = (slug) => {
        if (slug.includes("leto_2025")) return 1;
        if (slug.includes("lider_uspeshniy_start")) return 2;
        if (slug.includes("galileo_kidsee_leto")) return 3;
        if (slug.includes("shag_vpered_leto")) return 4;
        if (slug.includes("vipusknoy")) return 5;
        if (slug.includes("koniy_lager")) return 6;
        return -1;
      };
      const withHardcodedOrder = (items) => {
        return items
          .map((item) => ({ ...item, order: getOrderBySlug(item.slug) }))
          .sort((a, b) => {
            return a.order - b.order;
          });
      };
      await axios
        .get(`/api/v1/programm/main/`)
        .then((response) => { 
          this.programms_arr = withHardcodedOrder(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async GetSoloSlider() {
      await axios
        .get(`/api/v1/get/main/photos/`)
        .then((response) => {
          this.soloSlider = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async GetTextMain() {
      await axios
        .get(`/api/v1/get/mainText/`)
        .then((response) => {
          this.mainText = response.data[0] || {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async GetGalery() {
      await axios
        .get(`/api/v1/get/main/galery/`)
        .then((response) => {
          this.galery = response.data;
          this.allSliders = response.data.length - 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onScroll(e) {
      // this.windowTop = window.top.scrollY
      if (this.$refs?.card2?.getBoundingClientRect().top <= -140 || null) {
        this.card2done = true;
      } else {
        this.card2done = false;
      }

      if (this.$refs?.card3?.getBoundingClientRect().top <= -140 || null) {
        this.card3done = true;
      } else {
        this.card3done = false;
      }

      // if(this.$refs.galery.getBoundingClientRect().top <= -20 || null) {
      //    this.ToggleScroll()
      // }
    },
    CloseScroll() {
      this.scrollTogle = true;
      this.mouseWheel = true;
    },
    OpenScroll() {
      this.scrollTogle = false;
      enablePageScroll(this.$refs.galery);
      this.cssMode = true;
      this.mouseWheel = false;
    },
    ToggleScroll() {
      if (this.scrollTogle) {
        this.mouseWheel = true;
        disablePageScroll(this.$refs.galery);
      } else {
        enablePageScroll(this.$refs.galery);
        this.cssMode = true;
        this.mouseWheel = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);

    this.GetSoloSlider();

    this.logout();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  components: {
    Header,
    TeamCarousel,
    MainCarousel,
    SoloSlider,
  },
  setup() {
    onMounted(() => {
      AOS.init();
    });
  },
  created() {
    this.GetTeams();
    this.GetProgramms();
    this.GetGalery();
    this.GetTextMain();
  },
  watch: {
    currentSlider(value) {
      this.currentSlider = value;

      // if (this.currentSlider == this.allSliders) {
      //    this.OpenScroll()
      //    enablePageScroll(this.$refs.galery);
      // } else {
      //    this.CloseScroll()
      // }
    },
  },
};
</script>
